<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>

                <div>
                  
                  <form @submit.prevent="loginWithPassword">
                    <vs-input
                      autofocus="autofocus"
                      v-validate="'required|email'"
                      name="email"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label-placeholder="Email"
                      v-model="email"
                      class="w-full" />
                    <span class="text-danger text-sm">{{ errors.first('email') }}</span>

                    <vs-input
                      v-validate="'required'"
                      type="password"
                      name="password"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Password"
                      v-model="password"
                      class="w-full mt-6" />
                    <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                    <div class="flex flex-wrap justify-end my-5">
                      <!-- <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox> -->
                      <router-link to="/forgottenpassword">Forgot Password?</router-link>
                    </div>
                    <vs-button  type="border" @click="registerExternal">Register</vs-button>
                    <vs-button class="float-right" v-on:click="loginWithPassword">Login</vs-button>
                    <!-- To enable submit on enter -->
                    <vs-input type="submit" value="Submit" class="hidden" />
                  </form>
                  
                  <vs-divider>OR</vs-divider>

                  <div class="social-login-buttons flex flex-wrap items-center mt-4">

                    <!-- facebook -->
                    <div style="display: none">
                      <v-facebook-login 
                        @login='handleFbLogin'
                        v-model="fbSdk.model"
                        v-bind:app-id="fbSdk.facebookAppId"
                        @sdk-init="handleFbSdkInit"
                      ></v-facebook-login>
                    </div>
                    <div @click="loginWithFacebook" class="bg-facebook pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4">
                      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" class="text-white h-4 w-4 svg-inline--fa fa-facebook-f fa-w-9" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512"><path fill="currentColor" d="M215.8 85H264V3.6C255.7 2.5 227.1 0 193.8 0 124.3 0 76.7 42.4 76.7 120.3V192H0v91h76.7v229h94V283h73.6l11.7-91h-85.3v-62.7c0-26.3 7.3-44.3 45.1-44.3z"></path></svg>
                    </div>

                    <!-- GOOGLE -->
                    <div @click="loginWithGoogle" class="bg-google pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4">
                      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" class="text-white h-4 w-4 svg-inline--fa fa-google fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>
                    </div>

                    <!-- TWITTER -->
                    <div @click="loginWithTwitter" class="bg-twitter pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4">
                      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" class="text-white h-4 w-4 svg-inline--fa fa-twitter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
                    </div>
                    
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>

  </div>
</template>

<script>
import VFacebookLogin from 'vue-facebook-login-component'
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
export default{
  components: {
    VFacebookLogin
  },
  mixins:[TpNotificationMixin],
  data () {
    return {
      email: '',
      password: '',
      checkbox_remember_me: false,
      fbSdk: {
        FB: {},
        model: {},
        scope: {},
        facebookAppId: process.env.VUE_APP_FACEBOOK_APP_ID
      },
      google: {
        isInit: false,
        isSignIn: false
      }
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.email !== '' && this.password !== ''
    }
  },
  mounted () {
    // Google auth
    let that = this
    let checkGauthLoad = setInterval(function(){
      that.google.isInit = that.$gAuth.isInit
      that.google.isSignIn = that.$gAuth.isAuthorized
      if(that.isInit) clearInterval(checkGauthLoad)
    }, 1000);
  },
  methods: {
    loginWithPassword () {
      this.$validator.validateAll()
      .then((result) => {
        if (result) {
          this.$vs.loading();

          this.$store.dispatch('auth/loginWithPassword', {
            email: this.email, password: this.password
          })
          .then(response => {
            if ( response.data.needsTwoFactorCode ) {
              this.$store.dispatch('auth/persistOneTimeTwofactorToken', { oneTimeTwoFactorToken : response.data.oneTimeTwoFactorToken});
              this.$router.push({name: 'page-login-twofactor', query: { redirect: this.$route.query.redirect }})
              return
            } else if (response.data.needsTwoFactorSetup) {
              // Companies w/c Require2FA flag turned on
              this.$store.dispatch('auth/persistOneTimeTwofactorToken', { oneTimeTwoFactorToken : response.data.oneTimeTwoFactorToken});
              this.$router.push({name: 'page-login-twofactor-setup', query: { redirect: this.$route.query.redirect }})
              return
            }

            // Redirect to either start, requested (in query params) or home page
            this.$router.push(this.$route.query.redirect || response.data.startPageUrl || '/');
          })
          .catch(error => {
            this.$_notifyFailureByResponseData(error.response.data);
          })
          .finally(() => this.$vs.loading.close())

        } else {
          this.$_notifyFailure('Please enter a valid email and password')
        }
      });
    },
    registerExternal () {
      location.href = "https://support.travelgenix.io/hc/en-gb/articles/26468116193297-Access-to-Travelify";
    },
    loginWithFacebook () {
      //We need the access token response so user must login
      // this.fbSdk.FB.getLoginStatus(function(response) {
      //     if (response.status  !== 'connected') {
      //       this.fbSdk.FB.login();
      //     }
      // });
      if (this.fbSdk.scope.connected) this.fbSdk.scope.logout();
      // This will trigger onFacebookLogin after facebook login
      this.fbSdk.scope.login();
    },
    handleFbLogin (response) {
      if (!response || response.status !== 'connected') {
        return;
      }
      this.$vs.loading();
      this.$store.dispatch('auth/loginWithFacebook', {
        'facebookToken': response.authResponse.accessToken
      }).then(response => {
        //redirect to home page or requested page if specified in param
        this.$router.push(this.$route.query.redirect || response.data.startPageUrl || '/');
      }).catch(error => {
        this.$_notifyFailureByResponseData(error.response.data)
      })
      .finally(() => this.$vs.loading.close());
    },
    handleFbSdkInit({ FB, scope }) {
      this.fbSdk.FB = FB
      this.fbSdk.scope = scope
    },
    loginWithGoogle () {
      this.$gAuth.signIn()
        .then(googleUser => {
          // On success do something, refer to https://developers.google.com/api-client-library/javascript/reference/referencedocs#googleusergetid
          var id_token = googleUser.getAuthResponse().id_token
          this.google.isSignIn = this.$gAuth.isAuthorized
          if (this.$gAuth.isAuthorized && id_token) {
            this.$vs.loading();
            this.$store.dispatch('auth/loginWithGoogle', {
              'googleToken': id_token
            })
            .then(response => {
              //redirect to home page or requested page if specified in param
              this.$router.push(this.$route.query.redirect || response.data.startPageUrl || '/');
            })
            .catch(error => {
              this.$_notifyFailureByResponseData(error.response.data)
            })
            .finally(() => this.$vs.loading.close());
          }
        })
        .catch(error  => {
          // On fail do something
          console.log(error);
          this.$_notifyFailure('Oops something went wrong with google login. Please try again later')
        })
    },
    loginWithTwitter() {
      this.$vs.loading();
      this.$http.post('api/auth/token/twitter', { redirectRoute: this.$route.query.redirect })
      .then(response => {
        var childWindow = this.openPopupWindow(response.data, "Twitter Signin", window, 600, 700);
        childWindow.focus();
      })
      .catch((error) => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close());
    },
    openPopupWindow (url, windowName, win, w, h) {
      const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
      const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
      return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
    }
  }
}
</script>

<style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook { background-color: #1551b1 }
    .bg-twitter { background-color: #00aaff }
    .bg-google { background-color: #4285F4 }
    .bg-github { background-color: #333 }
  }
}

</style>
